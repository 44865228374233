export default {
  data() {
    return {
      navigationHin: '',
      verlasseSeite: false,
      verlasseSeiteAbfrage: false,
    };
  },

  beforeMount() {
    // Behandelt das Verhalten bei Aktualisierung der Seite, falls Daten eingegeben wurden
    window.addEventListener('beforeunload', this.behandleAktualisierung);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.behandleAktualisierung);
    });
    window.addEventListener('popstate', () => {
      window.popStateDetected = true;
    });
  },

  methods: {
    behandleAktualisierung(event) {
      if (this.eingegeben) {
        event.preventDefault();
        /* eslint no-param-reassign: "error" */
        event.returnValue = '';
      }
    },
    behandleSeiteVerlassen() {
      this.verlasseSeite = true;
      if (this.navigationHin === 'Login') {
        this.$root.$refs.mehr.resetKundenportal();
        this.$router.push(this.navigationHin);
      } else if (this.navigationHin === this.$store.state.vorherigeURL) {
        this.$router.back();
      } else {
        this.$router.push(this.navigationHin);
      }
      this.verlasseSeiteAbfrage = false;
    },
    schliesseAbfrage() {
      this.verlasseSeiteAbfrage = false;
      window.popStateDetected = false;
    },
    seiteVerlassen(to, next) {
      this.verlasseSeiteAbfrage = true;
      if (this.$store.state.mehrAktiv && (to.path !== '/profil') && (to.path !== '/vertreter') && (to.path !== '/')) {
        if (to.path === '/login') {
          this.$root.$refs.mehr.logoutAbfrage = true;
        }
        console.log('toggleMehr');
        this.$root.$refs.mehr.toggleMehr();
      }
      this.navigationHin = to.name;
      if ((to.path === '/profil') && !window.popStateDetected) {
        this.$root.$refs.mehr.toggleMehr();
        window.popStateDetected = false;
        if (this.eingegeben) {
          this.$root.$refs.mehr.setMehr(false);
        }
      } else if (to.path === '/login') {
        this.$root.$refs.mehr.toggleMehr();
        this.$root.$refs.mehr.logoutAbfrage = true;
      }
      next(false);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.verlasseSeite && this.eingegeben()) {
      this.seiteVerlassen(to, next);
    } else {
      next();
      document.body.style.overflow = 'auto';
      this.$root.$refs.mehr.scrollSonderfall = false;
    }
  },

  watch: {
    verlasseSeiteAbfrage(value) {
      if (value) {
        this.$root.$refs.mehr.scrollSonderfall = true;
        document.body.style.overflow = 'hidden';
      } else if (!value) {
        document.body.style.overflow = 'auto';
        this.$root.$refs.mehr.scrollSonderfall = false;
      }
    },
  },
};
